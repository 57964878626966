<template>
    <div>
        <!-- Profile dropdown -->
        <Menu
            v-if="authStore.userData"
            v-slot="{ open }"
            as="div"
            class="relative">
            <MenuButton class="-m-1.5 flex p-1.5 align-middle">
                <span class="sr-only">Open user menu</span>
                <div class="flex items-center relative">
                    <img
                        v-if="authStore.userData.image_url"
                        class="h-8 w-8 object-cover rounded-full bg-gray-50"
                        :src="authStore.userData.image_url"
                        alt="">
                    <Icon
                        v-else
                        name="heroicons:user-circle"
                        class="h-8 w-8 text-gray-400 dark:text-gray-300" />
                    <span
                        v-if="versionMismatch"
                        class="w-2 h-2 bg-danger-500 block absolute bottom-0 right-0 rounded-full" />
                </div>
                <span
                    class="hidden lg:flex lg:items-center">
                    <div class="lg:flex lg:flex-col lg:items-start">
                        <span
                            class="block ml-4 mb-1 text-sm font-semibold leading-none text-gray-900 dark:text-gray-100"
                            aria-hidden="true">{{ authStore.userData.fullname }}</span>
                        <span class="leading-none ml-4 text-sm text-gray-400 dark:text-gray-200">
                            {{ authStore.userData.organisation_name }}
                        </span>
                    </div>
                    <Icon
                        name="heroicons:chevron-down"
                        class="ml-2 h-5 w-5 text-gray-400 transition-transform duration-150 ease-in-out"
                        :class="{'rotate-180': open}"
                        aria-hidden="true" />
                </span>
            </MenuButton>
            <Transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
                <MenuItems
                    class="absolute right-0 z-40 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white dark:bg-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div
                        v-if="userData &&
                            userData.organisationUsers &&
                            userData.organisationUsers.length > 1"
                        class="px-4 py-3">
                        <BaseInput
                            v-model="userData.current_organisation_id"
                            :label="$t('settings.activeOrganisation')"
                            :placeholder="$t('settings.activeOrganisation')">
                            <BaseSelect
                                v-model="userData.current_organisation_id"
                                @change="setActiveOrganisation">
                                <option
                                    v-for="organisationUser in userData.organisationUsers"
                                    :key="organisationUser.organisation.id"
                                    :value="organisationUser.organisation.id">
                                    {{ organisationUser.organisation.name }}
                                </option>
                            </BaseSelect>
                        </BaseInput>
                    </div>

                    <div class="py-1">
                        <NuxtLink
                            :to="useLocalePath()('/settings/users/' + authStore.userData.id + '/userBaseData')">
                            <BaseMenuItem icon="heroicons:user">
                                {{ $t('menu.myProfile') }}
                            </BaseMenuItem>
                        </NuxtLink>
                        <NuxtLink
                            :to="useLocalePath()('/settings/organisations/' + authStore.userData.current_organisation_id + '/organisationBaseData')">
                            <BaseMenuItem icon="heroicons:building-office">
                                <span class="whitespace-nowrap">{{ $t('menu.myOrganisation') }}</span>
                            </BaseMenuItem>
                        </NuxtLink>

                        <slot name="additionalMenuItems" />
                    </div>

                    <BaseMenuItem
                        icon="heroicons:information-circle"
                        @click="showUserInfoModal = true">
                        <template #icon>
                            <span class="relative">
                                <Icon
                                    name="heroicons:information-circle"
                                    class="w-5 h-5" />

                                <span
                                    v-if="versionMismatch"
                                    class="w-2 h-2 bg-danger-500 block absolute bottom-0 right-0 rounded-full" />
                            </span>
                        </template>
                        {{ $t('menu.information') }}
                    </BaseMenuItem>
                    <BaseMenuItem
                        icon="heroicons:arrow-left-on-rectangle"
                        @click="logout">
                        {{ $t('menu.logout') }}
                    </BaseMenuItem>
                </MenuItems>
            </Transition>
        </Menu>
        <UserInfoModal v-model="showUserInfoModal" />
    </div>
</template>

<script setup>
import {useAuthStore} from "@/store/auth";
import {$lara} from "@/utils/$larafetch";
import {useNuxtApp} from "nuxt/app";
import {useNotification} from "@/composables/useNotification";
import {useLanguage} from "@/composables/useLanguage";


const loading = ref(false)
const showUserInfoModal = ref(false)
const authStore = useAuthStore()
const userData = computed(() => authStore.userData)
const {toggle: languageToggle, lang} = useLanguage()
const {versionIsUpToDate, versionMismatch} = useAppVersion()

const logout = async () => {
    loading.value = true
    await authStore.logout()
    loading.value = false
}

async function setActiveOrganisation() {
    $lara.put(useNuxtApp().$apiRoute("spa.settings.users.update", {user: userData.value.id}), {
        body: userData.value
    }).then(response => {
        authStore.setAuthData({
            abilities: authStore.abilities,
            userData: response.data,
            impersonate: authStore.impersonate
        })
    }).catch(error => {
        const notification = useNotification()
        const app = useNuxtApp()
        if (error && error.response && error.response.status === 422) {
            notification.error(app.$i18n.t('invalidRequestData'))
        } else if (error && error.response && error.response.status === 404) {
            notification.error(app.$i18n.t('modelDoesNotExist'))
        }
    })
}
</script>

<style scoped>

</style>